import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Status" } }, [_c("text-input", { key: "code", attrs: { "form-item": "", "label": "Status", "placeholder": "Type Status", "rules": "required|max:1", "max-length": 1, "span": 24 } }), _c("text-input", { key: "description", attrs: { "form-item": "", "label": "Description", "placeholder": "Type Description", "rules": "max:15", "max-length": 15, "span": 24 } }), _c("text-input", { key: "bpcsDisposition", attrs: { "form-item": "", "label": "BPCS Disposition", "placeholder": "Type BPCSDisposition", "rules": "max:3", "max-length": 3, "span": 24 } }), _c("switch-input", { key: "quarantine", attrs: { "form-item": "", "label": "Quarantine", "span": 12 } }), _c("switch-input", { key: "conditional", attrs: { "form-item": "", "label": "Conditional", "span": 12 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateStatus"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateStatus = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-status" }, [_c("resource", { attrs: { "name": "cf.statuses", "api-url": _vm.apiUrl, "create": _vm.CreateStatus, "redirect-route": "/container-filling/status" } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateStatus",
  data() {
    return {
      CreateStatus,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
